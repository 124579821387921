import React from 'react';

import { Box } from '@mui/material';
import { isNil } from 'lodash/fp';
import { TFunction } from 'react-i18next';
import { MRT_Cell, MRT_Row, MRT_RowData } from 'material-react-table';
import endOfDay from 'date-fns/endOfDay';

import { FilterType } from '@ibox/ui';

import { Status } from '../../components/status';
import { Translate } from '../../components/translate';
import { PRODUCT_GROUPS, PRODUCT_GROUPS_NAMES } from '../../constants';
import { ProductGroupIcon } from '../../components/product-group-icon';
import { LinkCell } from '../../components/link-cell';
import { routes } from '../../routes';
import { getPgListByCountry } from '../../utils/utils';
import { CountryCode } from '../../store/reducers';
import { OrdersData } from '../../store/reducers/orders';

import css from './index.module.scss';

export const RELEASE_METHOD_TYPES_NAMES = {
  PRODUCTION: 'Производство в стране',
  IMPORT: 'Ввезен в страну (импорт)',
  REMAINS: 'Маркировка остатков',
  REMARK: 'Перемаркировка',
  COMMISSION: 'Принят на комиссию от физического лица',
  EAEU: 'Ввезён из ЕАЭС',
};

export const CREATE_METHOD_TYPES_NAMES = {
  SELF_MADE: 'Самостоятельно',
  CEM: 'ЦЭМ',
  CM: 'Контрактное производство',
  CL: 'Логистический склад',
  CA: 'Комиссионная площадка',
};

export const RELEASE_METHOD_TYPES = [
  {
    value: 'PRODUCTION',
    label: Translate('Производство в стране'),
    countries: ['KZ', 'UZ', 'KG', 'AM'],
    productGroups: {
      KZ: [
        PRODUCT_GROUPS.shoes,
        PRODUCT_GROUPS.alcohol,
        PRODUCT_GROUPS.pharma,
        PRODUCT_GROUPS.milk,
        PRODUCT_GROUPS.lp,
        PRODUCT_GROUPS.water,
      ],
      UZ: [
        PRODUCT_GROUPS.alcohol,
        PRODUCT_GROUPS.beer,
        PRODUCT_GROUPS.water,
        PRODUCT_GROUPS.pharma,
        PRODUCT_GROUPS.appliances,
        PRODUCT_GROUPS.antiseptic,
        PRODUCT_GROUPS.milk,
      ],
      KG: [PRODUCT_GROUPS.tobacco, PRODUCT_GROUPS.alcohol],
      AM: [
        PRODUCT_GROUPS.tobacco,
        PRODUCT_GROUPS.alcohol,
        PRODUCT_GROUPS.beer,
        PRODUCT_GROUPS.water,
        PRODUCT_GROUPS.perfumery,
        PRODUCT_GROUPS.tires,
        PRODUCT_GROUPS.lp,
        PRODUCT_GROUPS.shoes,
        PRODUCT_GROUPS.milk,
        PRODUCT_GROUPS.laptops,
        PRODUCT_GROUPS.telephones,
        PRODUCT_GROUPS.softdrinks,
        PRODUCT_GROUPS.conserve,
        PRODUCT_GROUPS.chemistry,
        PRODUCT_GROUPS.coffeetea,
        PRODUCT_GROUPS.dyes,
        PRODUCT_GROUPS.seafood,
        PRODUCT_GROUPS.grocery,
        PRODUCT_GROUPS.sweets,
        PRODUCT_GROUPS.vegetableoil,
      ],
    },
  },
  {
    value: 'IMPORT',
    label: Translate('Ввезен в страну (Импорт)'),
    countries: ['KZ', 'UZ', 'KG', 'AM'],
    productGroups: {
      KZ: [
        PRODUCT_GROUPS.shoes,
        PRODUCT_GROUPS.alcohol,
        PRODUCT_GROUPS.pharma,
        PRODUCT_GROUPS.milk,
        PRODUCT_GROUPS.lp,
        PRODUCT_GROUPS.water,
        PRODUCT_GROUPS.milk,
      ],
      UZ: [
        PRODUCT_GROUPS.alcohol,
        PRODUCT_GROUPS.beer,
        PRODUCT_GROUPS.water,
        PRODUCT_GROUPS.pharma,
        PRODUCT_GROUPS.milk,
        PRODUCT_GROUPS.appliances,
      ],
      KG: [PRODUCT_GROUPS.tobacco, PRODUCT_GROUPS.alcohol],
      AM: [
        PRODUCT_GROUPS.tobacco,
        PRODUCT_GROUPS.alcohol,
        PRODUCT_GROUPS.beer,
        PRODUCT_GROUPS.water,
        PRODUCT_GROUPS.perfumery,
        PRODUCT_GROUPS.tires,
        PRODUCT_GROUPS.lp,
        PRODUCT_GROUPS.shoes,
        PRODUCT_GROUPS.milk,
        PRODUCT_GROUPS.laptops,
        PRODUCT_GROUPS.telephones,
        PRODUCT_GROUPS.softdrinks,
        PRODUCT_GROUPS.conserve,
        PRODUCT_GROUPS.chemistry,
        PRODUCT_GROUPS.coffeetea,
        PRODUCT_GROUPS.dyes,
        PRODUCT_GROUPS.seafood,
        PRODUCT_GROUPS.grocery,
        PRODUCT_GROUPS.sweets,
        PRODUCT_GROUPS.vegetableoil,
      ],
    },
  },
  {
    value: 'EAEU',
    label: Translate('Ввезён из ЕАЭС'),
    countries: ['AM'],
    productGroups: {
      AM: [
        PRODUCT_GROUPS.tobacco,
        PRODUCT_GROUPS.alcohol,
        PRODUCT_GROUPS.beer,
        PRODUCT_GROUPS.water,
        PRODUCT_GROUPS.perfumery,
        PRODUCT_GROUPS.tires,
        PRODUCT_GROUPS.lp,
        PRODUCT_GROUPS.shoes,
        PRODUCT_GROUPS.milk,
        PRODUCT_GROUPS.laptops,
        PRODUCT_GROUPS.telephones,
        PRODUCT_GROUPS.softdrinks,
        PRODUCT_GROUPS.conserve,
        PRODUCT_GROUPS.chemistry,
        PRODUCT_GROUPS.coffeetea,
        PRODUCT_GROUPS.dyes,
        PRODUCT_GROUPS.seafood,
        PRODUCT_GROUPS.grocery,
        PRODUCT_GROUPS.sweets,
        PRODUCT_GROUPS.vegetableoil,
      ],
    },
  },
  {
    value: 'REMAINS',
    label: Translate('Маркировка остатков'),
    countries: ['KZ', 'KG', 'UZ'],
    productGroups: {
      KZ: [PRODUCT_GROUPS.shoes],
      KG: [PRODUCT_GROUPS.alcohol],
      UZ: [PRODUCT_GROUPS.appliances],
    },
  },
  {
    value: 'REMARK',
    label: Translate('Перемаркировка'),
    countries: ['KZ'],
    productGroups: {
      KZ: [PRODUCT_GROUPS.shoes, PRODUCT_GROUPS.lp],
    },
  },
  {
    value: 'COMMISSION',
    label: Translate('Принят на комиссию от физического лица'),
    countries: ['KZ'],
    productGroups: {
      KZ: [PRODUCT_GROUPS.shoes, PRODUCT_GROUPS.lp],
    },
  },
];

export const CREATE_METHOD_TYPES = [
  { value: 'SELF_MADE', label: Translate('Самостоятельно') },
];

export const CREATE_METHOD_TYPES_WITH_SERVICE = [
  { value: 'CEM', label: Translate('ЦЭМ') },
  { value: 'CM', label: Translate('Контрактное производство') },
  { value: 'CL', label: Translate('Логистический склад') },
  { value: 'CA', label: Translate('Комиссионная площадка') },
];

export const EMISSION_TYPES = [
  { value: 'OPERATOR', label: Translate('Автоматически') },
  { value: 'SELF_MADE', label: Translate('Пользователем') },
];

export const FILE_TYPES = [
  { value: 'PDF', label: 'PDF' },
  { value: 'CSV', label: 'CSV' },
];

export const FILE_TEMPLATES = [
  { value: '1', label: Translate('В одну колонку') },
  { value: '2', label: Translate('На весь лист') },
];

export const STICKER_TEMPLATES = [
  { value: 'small', label: Translate('Простая белая') },
  { value: 'wideImport', label: Translate('Широкая этикетка (импорт)') },
  {
    value: 'wideRemains',
    label: Translate('Широкая этикетка (маркировка остатков)'),
  },
];

export const ORDER_STATUSES = {
  CREATED: 'Создан',
  PENDING: 'В ожидании',
  READY: 'Готов',
  REJECTED: 'Отклонен',
  CLOSED: 'Закрыт',
  EXPIRED: 'Аннулирован',
};

const PRODUCT_STATUSES = {
  ACTIVE: 'Активный',
  PENDING: 'Создан',
  REJECTED: 'Отклонен',
  CLOSED: 'Закрыт',
  EXPIRED: 'Аннулирован',
  EXHAUSTED: 'Исчерпан (не содержит кодов)',
};

export const getOrderStatusFilterOptions = (t: TFunction<'translation'>) => [
  { value: 'CREATED', label: t('Создан') },
  { value: 'PENDING', label: t('В ожидании') },
  { value: 'READY', label: t('Готов') },
  { value: 'REJECTED', label: t('Отклонен') },
  { value: 'CLOSED', label: t('Закрыт') },
  { value: 'EXPIRED', label: t('Аннулирован') },
];

export const getFiltersTabs = (t: TFunction<'translation'>) => [
  {
    label: t('Все фильтры'),
    value: 0,
  },
  {
    label: t('Избранные фильтры'),
    value: 1,
  },
  {
    label: t('История фильтров'),
    value: 2,
  },
];

export const getFilters = (
  t: TFunction<'translation'>,
  country: CountryCode,
  filterValues: Record<string, string | string[]> | null
) => {
  const maxDateFrom = filterValues?.dateTo
    ? endOfDay(new Date(filterValues.dateTo as string))
    : endOfDay(new Date());
  const maxDateTo = endOfDay(new Date());
  const minDateTo = filterValues?.dateFrom
    ? endOfDay(new Date(filterValues.dateFrom as string))
    : null;
  return [
    {
      id: 'orderId',
      label: t('Идентификатор заказа'),
      type: FilterType.TEXT,
      enableHiding: false,
    },
    {
      id: 'pg',
      label: t('Товарная группа'),
      type: FilterType.SELECT,
      config: {
        options: getPgListByCountry(country, t),
      },
    },
    {
      id: 'createDate',
      label: t('Дата создания'),
      type: FilterType.DATE_RANGE,
      config: {
        startLabel: t('От'),
        endLabel: t('До'),
        keyFrom: 'createDateStart',
        keyTo: 'createDateEnd',
        maxDateFrom,
        maxDateTo,
        minDateTo,
        maxDate: endOfDay(new Date()),
      },
    },
    {
      id: 'orderStatus',
      label: t('Статус'),
      type: FilterType.SELECT,
      config: {
        options: getOrderStatusFilterOptions(t),
      },
    },
  ];
};

export const getNewColumns = (
  t: TFunction<'translation'>,
  data: OrdersData[]
) => {
  const res = data.reduce((acc: number, item) => {
    const count = item.buffers.length;
    return count > acc ? count : acc;
  }, 1);

  return [
    {
      accessorKey: 'orderId',
      id: 'orderId',
      enableHiding: false,
      header: t('Идентификатор заказа'),
      Cell: ({ cell }: { cell: MRT_Cell<Record<string, unknown>> }) => (
        <Box className={css['order-id-container']}>
          <Box>
            <ProductGroupIcon
              pg={cell.row.original.pg as keyof typeof PRODUCT_GROUPS}
            />
          </Box>
          <Box>
            <LinkCell
              link={routes.orders(cell.getValue() as string)}
              text={cell.getValue() as string}
            />
            <Box>
              {t(
                PRODUCT_GROUPS_NAMES[
                  cell.row.original.pg as keyof typeof PRODUCT_GROUPS_NAMES
                ]
              )}
            </Box>
          </Box>
        </Box>
      ),
      enableSorting: false,
      size: 320,
    },
    {
      accessorKey: 'Product',
      id: 'Product',
      header: t('Товар'),
      enableSorting: false,
      Cell: ({ cell }: { cell: MRT_Cell<Record<string, unknown>> }) => {
        return (
          <>
            {cell.row.original.buffers.map((buffer, index) => {
              return (
                <>
                  <span>
                    {index + 1 < cell.row.original.buffers.length
                      ? `${buffer.gtin}, `
                      : buffer.gtin}
                  </span>
                  {(index + 1) % 5 === 0 && <br />}
                </>
              );
            })}
          </>
        );
      },
      size: 120 * (res > 5 ? 5 : res),
    },
    {
      accessorKey: 'createDate',
      id: 'createDate',
      header: t('Дата создания'),
      enableSorting: false,
    },
    {
      accessorKey: 'productCount',
      id: 'productCount',
      header: t('Количество товаров'),
      enableSorting: false,
    },
    {
      accessorKey: 'codesCount',
      id: 'codesCount',
      header: t('Количество кодов'),
      enableSorting: false,
    },
    {
      accessorKey: 'orderStatus',
      id: 'orderStatus',
      Cell: ({ cell }: { cell: MRT_Cell<Record<string, unknown>> }) => (
        <Status
          label={t(
            ORDER_STATUSES[cell.getValue() as keyof typeof ORDER_STATUSES]
          )}
          status={cell.getValue() as keyof typeof ORDER_STATUSES}
        />
      ),
      header: t('Статус'),
      enableSorting: false,
    },
  ];
};

export const rateTypeOptions = [
  {
    value: 0,
    label: Translate('Нулевой'),
  },
  {
    value: 1,
    label: Translate('Стандартный'),
  },
];

export const productHeaders = (t: TFunction<'translation'>) => [
  {
    accessorKey: 'gtin',
    id: 'gtin',
    header: t('Код товара'),
    enableSorting: false,
    enableHiding: false,
    maxSize: 150,
  },
  {
    accessorKey: 'cisType',
    id: 'cisType',
    header: t('Тип кода маркировки'),
    enableSorting: false,
    Cell: ({ row }: { row: MRT_Row<MRT_RowData> }) => {
      return <>{t(row.original.cisType)}</>;
    },
    maxSize: 200,
  },
  {
    accessorKey: 'rateType',
    id: 'rateType',
    accessor: ({ rateType }: { rateType: string }) => {
      if (isNil(rateType)) {
        return '—';
      }
      return t(rateType ? 'Стандартный' : 'Нулевой');
    },
    header: t('Тариф'),
    enableSorting: false,
    maxSize: 110,
  },
  {
    accessorKey: 'availableCodes',
    id: 'availableCodes',
    header: t('Всего кодов'),
    enableSorting: false,
    maxSize: 110,
  },
  {
    accessorKey: 'totalPassed',
    id: 'totalPassed',
    header: t('Получено кодов'),
    enableSorting: false,
    maxSize: 110,
  },
  {
    accessorKey: 'leftInBuffer',
    id: 'leftInBuffer',
    header: t('Остаток кодов'),
    enableSorting: false,
    maxSize: 110,
  },
  {
    accessorKey: 'bufferStatus',
    id: 'bufferStatus',
    header: t('Статус'),
    Cell: ({ cell }: { cell: MRT_Cell<Record<string, unknown>> }) => {
      return (
        <Status
          isProductStatus={true}
          label={t(
            PRODUCT_STATUSES[cell.getValue() as keyof typeof PRODUCT_STATUSES]
          )}
          status={cell.getValue() as keyof typeof PRODUCT_STATUSES}
        />
      );
    },
    enableSorting: false,
  },
];

export const availableCodesHeaders = (t: TFunction<'translation'>) => [
  {
    accessorKey: 'gtin',
    id: 'gtin',
    header: t('Код товара'),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'packId',
    id: 'packId',
    header: t('Идентификатор пакета (партии)'),
    enableSorting: false,
  },
  {
    accessorKey: 'packDateTime',
    id: 'packDateTime',
    header: t('Дата получения'),
    enableSorting: false,
  },
  {
    accessorKey: 'count',
    id: 'count',
    header: t('Получено кодов'),
    enableSorting: false,
  },
];

export const formatQuantityCodes =
  (
    quantityCodes: string,
    setQuantityCodes: (val: string | number) => void,
    fileType: string
  ) =>
  (v: string) => {
    if (isNil(v) || v === '') {
      setQuantityCodes('');
      return '';
    }
    const maxCodes = fileType === 'PDF' ? 500 : 30000;
    const val = parseInt(v, 10);
    const formattedValue = val <= maxCodes ? val : quantityCodes;
    setQuantityCodes(formattedValue);
    return formattedValue;
  };

export const DEFAULT_PRODUCT_LINE = {
  file: null,
  serialNumbers: [],
  gtin: '',
  cisType: '',
  quantity: '',
  serialNumberType: '',
};

export const DISABLED_STATUSES = ['EXHAUSTED', 'REJECTED', 'CLOSED', 'EXPIRED'];
export const CLOSE_BUFFER_STATUSES = ['EXHAUSTED', 'ACTIVE'];
